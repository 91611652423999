.app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-container {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: flex-end;
}

.logo {
  width: 100px;
  cursor: pointer;
}

.logo-subscript {
  margin: 0 0 0 3px;
  color: #c1db5a;
  font-family: 'MetroSans', sans-serif;
  font-size: 12px;
  line-height: 12px;
}

.conversation-container {
  width: 50%;
  height: 80%;
  background-color: #fcf0e3;
  border-radius: 8px;
  box-shadow: 0 5px 20px 0 #040707;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chat-container {
  width: 100%;
  margin-bottom: 10px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

.chat-container::-webkit-scrollbar {
  width: 5px;
}

.chat-container::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: rgba(179, 161, 147, 0.25);
}

.chat-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #152724;
}

.phantom-div {
  width: 0;
  height: 0;
}

.footnote {
  text-align: center;
  margin-top: 5px;
  color: #b3a193;
  font-family: 'MetroSans', sans-serif;
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  .app {
    justify-content: flex-start;
  }

  .logo-container {
    position: inherit;
    align-self: flex-start;
    margin: 10px;
  }

  .conversation-container {
    width: calc(100% - 50px);
    height: 77%;
    padding: 25px;
  }
}
